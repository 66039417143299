<template>
    <div>
        <div class="hb-primary hb-design-system-border-radius px-4 white--text mb-6">
            <hb-header :divider="false">
                <hb-page-header
                    title="Header Info (Deprecated)"
                >
                    <template v-slot:description>
                        <div>
                            <div>!! deprecated !! do not use anymore !!</div>
                        </div>
                    </template>
                </hb-page-header>
            </hb-header>
        </div>

        <hb-notification type="caution" :dismissable="false">This component is deprecated. Please do not use this anymore. Use the <hb-link to="/design-system/mini-profiles">HbMiniProfile</hb-link> component instead.</hb-notification>

        <hb-card title="Contact Header Info Example + Extra Surrounding Elements Example + Code" class="mt-4 mb-6">
            <div class="mb-4 mx-6">
                <hb-header one-action>
                    <template v-slot:left>
                        <HbHeaderInfo
                            type="contact"
                            title="John Smith"
                            is-military
                            status="gate lockout"
                            phone="+1 (310) 234-2354"
                            phone-is-verified
                            email="johnsmith@somewebsite.coms"
                            email-is-verified
                            address="100 Main Street, Los Angeles 90039"
                            multiple-access-codes
                            @emailClicked="handleEmittedClickEvent('@emailClicked')"
                            @pin="handleEmittedClickEvent('@pin')"
                        />
                    </template>
                    <template v-slot:right>
                        <HbBtn
                            class="ml-1"
                            icon
                            tooltip="Open Menu"
                            mdi-code="mdi-table-actions-custom-1"
                        />
                    </template>
                </hb-header>
            </div>

        <hb-card class="pa-6" color="#E0F5F5" no-title>
<pre>
&lt;hb-header one-action>
    &lt;template v-slot:left>
        &lt;HbHeaderInfo
            type="contact"
            title="John Smith"
            is-military
            status="gate lockout"
            phone="+1 (310) 234-2354"
            phone-is-verified
            email="johnsmith@somewebsite.coms"
            email-is-verified
            address="100 Main Street, Los Angeles 90039"
            accessCode="1234"
            @emailClicked="handleEmittedClickEvent('@emailClicked')"
            @pin="handleEmittedClickEvent('@pin')"
        />
    &lt;/template>
    &lt;template v-slot:right>
        &lt;HbBtn
            class="ml-1"
            icon
            tooltip="Open Menu"
            mdi-code="mdi-table-actions-custom-1"
        />
    &lt;/template>
&lt;/hb-header>
</pre>
            </hb-card>
        </hb-card>

        <hb-card title="Space Header Info Vanilla Example + Code" class="mt-4 mb-6">
            <div class="mb-4 mx-6 mt-4">
                <HbHeaderInfo
                    type="storage"
                    title="Space A1"
                    has-insurance
                    insurance-name="Insurance Name"
                    insurance-description="Insurance Description"
                    status="leased"
                    category-one="Category 1"
                    category-two="Category 2"
                    feature-one="Feature 1"
                    feature-two="Feature 2"
                    amenity-one="Amenity 1"
                    amenity-two="Amenity 2"
                    property="S0123 - Fullerton - 123 Ocean Avenue"
                    accessCode="1234"
                    @pin="handleEmittedClickEvent('@pin')"
                />
            </div>

            <hb-card class="pa-6" color="#E0F5F5" no-title>
<pre>
&lt;HbHeaderInfo
    type="storage"
    title="Space A1"
    has-insurance
    insurance-name="Insurance Name"
    insurance-description="Insurance Description"
    status="leased"
    category-one="Category 1"
    category-two="Category 2"
    feature-one="Feature 1"
    feature-two="Feature 2"
    amenity-one="Amenity 1"
    amenity-two="Amenity 2"
    property="S0123 - Fullerton - 123 Ocean Avenue"
    accessCode="1234"
    @pin="handleEmittedClickEvent('@pin')"
/>
</pre>
            </hb-card>
        </hb-card>

        <hb-card title="Props" class="my-6 pb-1">
            <hb-data-table
                :headers="propHeaders"
                :items="propItems"
                class="pa-6"
            >
            </hb-data-table>
        </hb-card>

        <hb-card title="Events" class="my-6 pb-1">
            <hb-data-table
                :headers="eventHeaders"
                :items="eventItems"
                class="pa-6"
            >
            </hb-data-table>
        </hb-card>

        <hb-global-notification
            v-model="success"
            type="success"
            :description="description"
        >
        </hb-global-notification>

    </div>
</template>
<script type="text/babel">

    export default {
        name: "DesignSystemHeaderInfo",
        data: function() {
            return {
                success: false,
                description: '',
                propHeaders: [
                    { text: 'Name', value: 'name', width: '125' },
                    { text: 'Type', value: 'type' },
                    { text: 'Default', value: 'default', width: '100' },
                    { text: 'Description', value: 'description' },
                ],
                propItems: [
                    { name: 'type', type: 'string', default: 'undefined', description: 'Sets the type of the avatar icon and the type of info and props displayed. Possible options are "contact", "storage", "parking", and "residential".' },
                    { name: 'title', type: 'string', default: 'undefined', description: 'Sets the title text.' },
                    { name: 'status', type: 'string', default: 'undefined', description: 'Manually set the status chip text. Color will be set automatically by the text string used.' },
                    { name: 'status-off', type: 'boolean', default: 'false', description: 'If set to true, turns off the display of the status chip.' },
                    { name: 'is-military', type: 'boolean', default: 'false', description: 'If set to true, turns on the military icon for "contact" type only.' },
                    { name: 'has-insurance', type: 'boolean', default: 'false', description: 'If set to true, turns on the insurance icon for "storage", "parking", and "residential" types only.' },
                    { name: 'insurance-name', type: 'string', default: 'undefined', description: 'Manually set the insurance name tooltip text when insurance icon is hovered over.' },
                    { name: 'insurance-description', type: 'string', default: 'undefined', description: 'Manually set the insurance description tooltip text when insurance icon is hovered over.' },
                    { name: 'phone', type: 'string', default: 'undefined', description: 'Manually set the phone number text displayed for "contact" type only.' },
                    { name: 'phone-is-verified', type: 'boolean', default: 'false', description: 'If set to true, this will add a verified icon next to the phone number.' },
                    { name: 'sms-click', type: 'boolean', default: 'false', description: 'If set to true, makes the phone number clickable and emits the "smsClicked" event' },
                    { name: 'email', type: 'string', default: 'undefined', description: 'Manually set the email text displayed for "contact" type only.' },
                    { name: 'email-is-verified', type: 'boolean', default: 'false', description: 'If set to true, this will add a verified icon next to the email.' },
                    { name: 'disable-email', type: 'boolean', default: 'false', description: 'If set to true, turns off the display of the email.' },
                    { name: 'address', type: 'string', default: 'undefined', description: 'Manually set the address text displayed for "contact" type only.' },
                    { name: 'access-code', type: 'string', default: 'null', description: 'Pass in the access code to display the access code which emits the "pin" event when clicked.' },
                    { name: 'multiple-access-codes', type: 'boolean', default: 'false', description: 'If set to true, this will turn the access code text to say "Mulitple Access Codes" instead of the string passed to "access-code" prop.' },
                    { name: 'contact-is-business', type: 'boolean', default: 'false', description: 'If set to true, this will turn the change the correct avatar icon to indicate business for contact type.' },
                    { name: 'access-error', type: 'boolean', default: 'false', description: 'If set to true, this will turn the access code text color to our error color.' },
                    { name: 'disable-code', type: 'boolean', default: 'false', description: 'If set to true, turns off the display of the access code.' },
                    { name: 'category-one', type: 'string', default: 'undefined', description: 'Manually set category-one text for "storage", "parking", and "residential" types only.' },
                    { name: 'category-two', type: 'string', default: 'undefined', description: 'Manually set category-two text for "storage", "parking", and "residential" types only.' },
                    { name: 'feature-one', type: 'string', default: 'undefined', description: 'Manually set feature-one text for "storage", "parking", and "residential" types only.' },
                    { name: 'feature-two', type: 'string', default: 'undefined', description: 'Manually set feature-two text for "storage", "parking", and "residential" types only.' },
                    { name: 'amenity-one', type: 'string', default: 'undefined', description: 'Manually set amenity-one text for "storage", "parking", and "residential" types only.' },
                    { name: 'amenity-two', type: 'string', default: 'undefined', description: 'Manually set amenity-two text for "storage", "parking", and "residential" types only.' },
                    { name: 'property', type: 'string', default: 'undefined', description: 'Manually set property text for "storage", "parking", and "residential" types only.' },
                    { name: 'row-max-width', type: 'string', default: 'undefined', description: 'Manually set a max width of the entire header info container. In most cases, you will not need to use this prop, but it is here for added flexibility.' },
                ],
                eventHeaders: [
                    { text: 'Name', value: 'name' },
                    { text: 'Description', value: 'description' },
                ],
                eventItems: [
                    { name: '@emailClicked', description: 'Event for when email address is clicked.' },
                    { name: '@smsClicked', description: 'Event for when "sms-click" prop is set to true and the phone number is clicked.' },
                    { name: '@pin', description: 'Event for when the access code or "Multiple Access Codes" are clicked.' },
                ],
            };
        },
        methods: {
            handleEmittedClickEvent(e){
                this.success = true;
                this.description = "This " + e + " action is emitted from it's base level custom component.";
            }
        }
    }
</script>

<style scoped>

</style>
